import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import actions from './actions';
import { menuReducers  } from './slice';
import Api from './api';
import { adaptBottomMenu, adaptSeoList, formatMenuUrl } from './utils';
import { settingsReducers as settingsReducer } from '../settings/slice';
import { logger } from '../../helpers/debugLogger';
import { AxiosApiResponse } from '../../service/types';
import { ILeftMenu, IBottomMenuContent, IMenuResponse } from './types';
import { ISagaActionType } from '../types';

const API = new Api();
function* getBottomMenu() {
	yield takeLatest(actions.GET_BOTTOM_MENU, function*() {
		try {
			const result: AxiosApiResponse<IMenuResponse> = yield call(API.getBottomMenu);
			if (result && result.status === 200) {
				const { data } = result.data;
				const sitemap = formatMenuUrl(data.items,'url','content_title');
				const adapted = adaptBottomMenu(data);
				// const adaptedMenu = adaptMenu(data);
				yield put(menuReducers.setBottomMenu(adapted));
				yield put(menuReducers.setSitemap(sitemap));
			}
		} catch (e) {
			logger.log('e',e);
		}
	});
}

function* getBottomLeftMenu() {
	yield takeLatest(actions.GET_LEFT_MENU, function*() {
		try {
			const result: AxiosApiResponse<ILeftMenu> = yield call(API.getBottomLeftMenu);
			if (result && result.status === 200) {
				const { data } = result.data;
				const sitemap = formatMenuUrl(data.items,'url','content_title');
				yield put(menuReducers.setBottomLeftMenu(data));
				yield put(menuReducers.setSitemap(sitemap));
			}
		} catch (e) {
			logger.log('e',e);
		}
	});
}

function* getBottomMenuContent() {
	yield takeLatest(actions.GET_BOTTOM_MENU_CONTENT, function* (action: ISagaActionType<string>) {
		yield put(menuReducers.setBottomMenuUI({ loading: true }));
		try {
			const { data } = action;
			const result: AxiosApiResponse<IBottomMenuContent[]> = yield call(API.getBottomMenuContent, data);
			if (result && result.status === 200) {
				const { data } = result.data;

				// @ts-expect-error FIXME: change adaptSEO arg types
				const adaptedSeo = adaptSeoList(data);

				if (adaptedSeo) {
					const [pageSeo] = adaptedSeo;
					yield put(settingsReducer.setSEOData(pageSeo));
				}

				if (data.length) {
					yield put(menuReducers.setBottomMenuContent(data[0]));
				}
			}
		} catch (e) {
			logger.log('e', e);
		}
		yield put(menuReducers.setBottomMenuUI({ loading: false }));
	});

}

function* menuSaga() {
	yield all([
		fork(getBottomMenu),
		fork(getBottomLeftMenu),
		fork(getBottomMenuContent),
	]);
}

export default menuSaga;
