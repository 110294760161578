import { IGame } from '../../../redux/games/games_list/types';
import { GAMES_WEIGHT } from '../../../redux/games/games_grid/utils';
import { IProvidersAllEntities } from '../../../redux/settings/types';

export function checkImageSize(game?: IGame, weight?: number) {
	if (!game || !weight) return;
	const { image_sm_url , image_md_url, image_lg_url } = game;
	
	const smImg = image_sm_url?.startsWith('http') ? image_sm_url : `${image_sm_url}?format=webp`;
	const mdImg = image_md_url?.startsWith('http') ? image_md_url : `${image_md_url}?format=webp`;
	const lgImg = image_lg_url?.startsWith('http') ? image_lg_url : `${image_lg_url}?format=webp`;

	const defaultImage = lgImg || mdImg || smImg;

	switch (weight) {
		case GAMES_WEIGHT.small:
			// image_lg_url has aspect ratio 1:1 for GAMEHUB provider.
			// so we use it for small and large grid items
			return lgImg || defaultImage;

		case GAMES_WEIGHT.medium:
			return mdImg || defaultImage;

		default:
			return lgImg || defaultImage;
	}
}

export const createProviderName = (subID: number, providers: IProvidersAllEntities): string => {
	const providerItem = providers[subID];
	return providerItem?.name || '';
};