import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAMES_WEIGHT } from '../../../redux/games/games_grid/utils';
import { IGameProps } from './types';
import './game-item.scss';
import { translate } from '../../../translations/utils';
import LazyImage from '../../LazyImage/LazyImage';

const GameGridItem: FC<IGameProps> = ({
	imgSrc,
	name= '',
	providerName,
	handleClick,
	weightGame= GAMES_WEIGHT.small,
}) => {
	const [isHovering, setIsHovering] = useState(false);

	const hover = (val: boolean) => {
		setIsHovering(val);
	};
	return (
		<div
			className="game_grid_item"
			onMouseEnter={() => hover(true)}
		>
			<div className="game_grid_image-item">
				<LazyImage width="300" height="300" src={imgSrc || ''} alt={name} title={name} loading={'lazy'}/>
			</div>
			<div
				onMouseLeave={() => hover(false)}
				className={`game_grid_info ${isHovering ? 'show_hover' : ''}`}
			>
				<div className="game_grid_name">{name}</div>
				<div className="game_grid_description">{providerName}</div>
				<div className={`btn_group ${weightGame === GAMES_WEIGHT.small ? 'game_flex' : ''}`}>
					<button aria-label={translate('play')} className="play_btn btn " onClick={handleClick}>
						<span><FormattedMessage id="play"/></span>
					</button>
					{/*<button className="play_btn_demo btn" onClick={handleClick}>*/}
					{/*	DEMO*/}
					{/*</button>*/}
				</div>
			</div>
		</div>
	);
};

export default GameGridItem;