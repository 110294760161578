import React from 'react';
import CategoryPreloader from './components/category-preloader';
import ProviderPreloader from './components/provider-preloader';
import GamesListPreloader from './components/games-list-preloader';
import GamesGroupPreloader from './components/games-group-preloader';
import ProfilePreloader from './components/profile-preloader';
import InboxPreloader from './components/inbox-preloader';
import HistoryPreloader from './components/history-preloader';
import PromotionPreloader from './components/promotion-preloader';
import SearchPreloader from './components/search-preloader';
import SearchedGamesPreloader from './components/search-list-preloader';
import MobileSGPreloader from './components/mobile-sg-preloader';
import {
	ProfilePageReloadPreloader,
	TournamentBannerPreloader,
	TournamentPrizePreloader,
} from './components';
import TournamentConditionsPreloader from './components/tournamentConditionsPreloader';
import WinnersListPreloader from './components/winners-list-preloader';
import { IPreloader, IPreloaderProps } from './types';
import './preloader.scss';
import BannerPreloader from './components/banner-preloader';
import InfoPreloader from './components/info-preloader';
import PaymentPreloader from './components/payments-preloader';
import StartGamePreloader from './components/start-game-preloader';
import ProfileDetailsPreloader from './components/profile-details-preloader';
import MainPreloader from './components/main-preloader';
import PromotionInnerPreloader from './components/PromotionInnerPreloader';
import HeaderPreloader from './components/header-preloader';

const Preloader: React.FC<IPreloaderProps> = (props) => {
	const { section, itemCount, className, headClassName, pageNumber } = props;
	const Preloader: IPreloader = {
		category  : <CategoryPreloader/>,
		provider  : <ProviderPreloader/>,
		gamesList : <GamesListPreloader/>,
		gamesGroup: <GamesGroupPreloader
			itemCount={itemCount}
			className={className}
			headClassName={headClassName}
			pageNumber={pageNumber}
		/>,
		profile: <ProfilePreloader/>,
		inbox  : <InboxPreloader/>,
		history: <HistoryPreloader
			itemLength={itemCount}
		/>,
		promotions               : <PromotionPreloader/>,
		promotionInner           : <PromotionInnerPreloader/>,
		search                   : <SearchPreloader/>,
		searchedGames            : <SearchedGamesPreloader/>,
		mobileSearchedGames      : <MobileSGPreloader/>,
		tournamentInnerBanner    : <TournamentBannerPreloader/>,
		tournamentInnerPrize     : <TournamentPrizePreloader/>,
		tournamentInnerConditions: <TournamentConditionsPreloader/>,
		winners                  : <WinnersListPreloader/>,
		banner                   : <BannerPreloader/>,
		info                     : <InfoPreloader/>,
		payments                 : <PaymentPreloader/>,
		startGame                : <StartGamePreloader/>,
		profileDetails           : <ProfileDetailsPreloader/>,
		profilePageReload        : <ProfilePageReloadPreloader/>,
		header                   : <HeaderPreloader/>,
		main                     : <MainPreloader/>,
	};
	return (
		<div className="preloader custom_container">
			{Preloader[section]}
		</div>
	);
};

export default Preloader;
