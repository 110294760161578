import { IActionRecentGamesData } from './types';

const actions = {
	GET_TOP_WINNERS_LIST    : 'GET_TOP_WINNERS_LIST',
	GET_LAST_WINNERS_LIST   : 'GET_LAST_WINNERS_LIST',
	GET_RECENTLY_PLAYED_LIST: 'GET_RECENTLY_PLAYED_LIST',

	getTopWinnersAction: () => {
		return {
			type: actions.GET_TOP_WINNERS_LIST,
		};
	},

	getLastWinnersAction: () => {
		return {
			type: actions.GET_LAST_WINNERS_LIST,
		};
	},

	getRecentlyPlayedAction: (data?: IActionRecentGamesData) => {
		return {
			type: actions.GET_RECENTLY_PLAYED_LIST,
			data,
		};
	},

};

export default actions;
