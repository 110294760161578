import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { winnersReducers } from './slice';
import { logger } from '../../helpers/debugLogger';
import Api from './api';
import { AxiosApiResponse } from '../../service/types';
import { getHeaderTotalCount } from '../utils';
import { RootState } from '../store';
import action  from './actions';
import { IActionRecentlyGames, IParamsRecentlyGames, IWinner, IWinnersGetStore } from './types';


const API = new Api();
const getStoreData = ({ Settings, Profile, Winners }: RootState): IWinnersGetStore => {
	const { channelID } = Settings;
	const currencyId = Profile.profileData.currency_id;
	const { recentlyPlayedGames } = Winners;
	return {
		channelID,
		currencyId,
		recentlyPlayedGames,
	};
};

function* getTopWinners() {
	yield takeEvery(action.GET_TOP_WINNERS_LIST, function* () {
		yield put(winnersReducers.setUI({ loading: true }));
		const { channelID, currencyId }: IWinnersGetStore = yield select(getStoreData);
		
		try {
			const params = { limit: 20, channel_id: channelID, currency_id: currencyId };
			const result: AxiosApiResponse<IWinner[]> = yield call(API.getTopWinnersList, params);
			if (result && result.status === 200) {
				yield put(winnersReducers.setTopWinners(result.data.data));
			}
		} catch (e) {
			logger.log('e', e);
		} finally { 
			yield put(winnersReducers.setUI({ loading: false }));
		}
	});
}

function* getLastWinners() {
	yield takeEvery(action.GET_LAST_WINNERS_LIST, function* () {
		yield put(winnersReducers.setUI({ loading: true }));
		const { channelID, currencyId }: IWinnersGetStore = yield select(getStoreData);

		try {
			const params = { limit: 20, channel_id: channelID, currency_id: currencyId };
			const result: AxiosApiResponse<IWinner[]> = yield call(API.getLastWinnersList, params);
			if (result && result.status === 200) {
				yield put(winnersReducers.setLastWinners(result.data.data));
			}
			yield put(winnersReducers.setUI({ loading: false }));
		} catch (e) {
			yield put(winnersReducers.setUI({ loading: false }));
			logger.log('e', e);
		}
	});
}

function* getRecentlyPlayedGames() {
	yield takeEvery(action.GET_RECENTLY_PLAYED_LIST, function* (action: IActionRecentlyGames) {
		yield put(winnersReducers.setUI({ loading: true }));
		const { page, limit } = action.data;
		const { recentlyPlayedGames }: IWinnersGetStore = yield select(getStoreData);

		const params:IParamsRecentlyGames = {
			page,
			limit,
		};

		try {
			const result: AxiosApiResponse<IWinner[]> = yield call(API.getRecentlyPlayedGamesList, params);
			if (result && result.status === 200) {
				const { data } = result.data;
				const xTotalCount = getHeaderTotalCount(result);
				yield put(winnersReducers.setUI({ hasMore: data.length + recentlyPlayedGames.length > xTotalCount }));
				yield put(winnersReducers.setRecentlyPlayedGames(data));
			}
			yield put(winnersReducers.setUI({ loading: false }));
		} catch (e) {
			yield put(winnersReducers.setUI({ loading: false }));
			logger.log('e', e);
		}
	});
}


export default function* winnersSaga() {
	yield all([
		fork(getTopWinners),
		fork(getLastWinners),
		fork(getRecentlyPlayedGames),
	]);
}