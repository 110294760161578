
export class Converter {
	criticalPoints = {
		K: 1e3, // 1e3 === 1_000 KILO
		M: 1e6, // 1e6 === 1_000_000 MEGA
		B: 1e9, // 1e9 === 1_000_000_000 BILLION
		T: 1e12, // 1e12 === 1_000_000_000_000 TRILLION
		Q: 1e15, // 1e15 === 1_000_000_000_000_000 QUADRILLION
		P: 1e18, // 1e18 === 1_000_000_000_000_000_000 PENTILLION
		E: 1e21, // 1e21 === 1_000_000_000_000_000_000_000 EXA
		Z: 1e24, // 1e24 === 1_000_000_000_000_000_000_000_000 ZETTA
		Y: 1e27, // 1e27 === 1_000_000_000_000_000_000_000_000_000 YOTTA
	};
	constructor() {
		if (!new.target) {
			return new Converter();
		}
	}

	toCompactString(balance: string, bonus:number = 0, precision: number = 0) {
		const num = parseFloat(balance || '0') + parseFloat(String(bonus));
		if (isNaN(num)) {
			return 0;
		}

		if (Math.abs(num) >= this.criticalPoints.Y) {
			return (num / this.criticalPoints.Y).toFixed(precision).toString() + 'Y';
		}
		if (Math.abs(num) >= this.criticalPoints.Z) {
			return (num / this.criticalPoints.Z).toFixed(precision).toString() + 'Z';
		}
		if (Math.abs(num) >= this.criticalPoints.E) {
			return (num / this.criticalPoints.E).toFixed(precision).toString() + 'E';
		}
		if (Math.abs(num) >= this.criticalPoints.P) {
			return (num / this.criticalPoints.P).toFixed(precision).toString() + 'P';
		}
		if (Math.abs(num) >= this.criticalPoints.Q) {
			return (num / this.criticalPoints.Q).toFixed(precision).toString() + 'Q';
		}
		if (Math.abs(num) >= this.criticalPoints.T) {
			return (num / this.criticalPoints.T).toFixed(precision).toString() + 'T';
		}
		if (Math.abs(num) >= this.criticalPoints.B) {
			return (num / this.criticalPoints.B).toFixed(precision).toString() + 'B';
		}
		if (Math.abs(num) >= this.criticalPoints.M) {
			return (num / this.criticalPoints.M).toFixed(precision).toString() + 'M';
		}

		if (Math.abs(num) >= this.criticalPoints.K) {
			return (num / this.criticalPoints.K).toFixed(precision).toString() + 'K';
		}

		return num;

	}
}

