// import { IBannerParams } from '../banners/types';
import { PartialConfigs } from '../../service/types';
import { apiRequest } from '../../service/service';
import { IParamsRecentlyGames } from './types';

export default class Games {
	getTopWinnersList = (params: NonNullable<unknown>) => {
		const url = '/v1/casino/maxWinners';
		const config: PartialConfigs<void, unknown> = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};

	getLastWinnersList = (params: NonNullable<unknown>) => {
		const url = '/v1/casino/recentWinners';
		const config: PartialConfigs<void, unknown> = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};

	getRecentlyPlayedGamesList = (params: IParamsRecentlyGames) => {
		const url = '/v1/recentPlayedGames';
		const config: PartialConfigs<void, unknown> = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};
}
