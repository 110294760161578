import React from 'react';
import { Device } from '../../../helpers/devices';

const HistoryPreloader: React.FC<{itemLength?: number}> = ({ itemLength  }) => {

	const length = Device() === 'mobile' ? 4 : itemLength || 10;
	return (
		<ul className="table_preloader">
			{Array.from({ length }, (_, i) => i * 2).map(item => <li key={item} />)}
		</ul>
	);
};

export default HistoryPreloader;