import React, { FC, memo } from 'react';
import { useRootAppNavigate } from '../../../hooks/useAppNavigate';
import { Navigate } from 'react-router';
import { ROUTE_PATH } from '../../../routes/utils';
import { profileSelectors } from '../../../redux/profile/selectors';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { gamesSelectors } from '../../../redux/games/games_list/selectors';
import { selectAllProvidersEntities, settingsInitial } from '../../../redux/settings/selectors';
import { modalReducers } from '../../../redux/modals/slice';
import Game from './game-item';
import GameGridItem from './game-grid-item';
import { IGameItemDistributorProps } from './types';
import { checkImageSize, createProviderName } from './utils';

const {
	selectProfileData,
} = profileSelectors;

const {
	setLoginUI,
} = modalReducers;
const {
	selectCategories,
} = gamesSelectors;

const GameItemDistributor:FC<IGameItemDistributorProps> = (props) => {

	const { enable_game_grid } = useAppSelector(settingsInitial);
	const { record, weightGame = 2, ...rest } = props;
	const navigate = useRootAppNavigate();
	const user = useAppSelector(selectProfileData);
	const propsData = record || rest;
	const { name, alias: gameAlias, provider_id } = propsData;
	const categories = useAppSelector(selectCategories);
	const providersEntities = useAppSelector(selectAllProvidersEntities);
	const dispatch = useAppDispatch();

	if (!categories) {
		return  <Navigate to={ROUTE_PATH.home} />; // This is a workaround, as the whole component should be refactored.
	}
	const subID = provider_id || -1;
	const providerName = createProviderName(subID, providersEntities);


	const handleClick = () => {
		if (user && user.id) {
			navigate(`game/${gameAlias}`);
		} else {
			dispatch(setLoginUI({ visible: true, error: '', redirect: `/game/${gameAlias}` }));
		}
	};
	const imgSrc = checkImageSize(propsData, weightGame);

	return (
		!enable_game_grid ? (
			<Game
				imgSrc={imgSrc || ''}
				providerName={providerName}
				handleClick={handleClick}
				name={name}
				enable_game_grid={enable_game_grid}
			/>
		) : (
			<GameGridItem
				imgSrc={imgSrc || ''}
				providerName={providerName}
				handleClick={handleClick}
				name={name}
				weightGame={weightGame}
			/>
		)
	);
};

const MemoizedGame = memo(GameItemDistributor);

export default MemoizedGame;
