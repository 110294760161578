import { WINNERS_TOGGLE } from '../../../constants/common';
import { IWinner } from '../../../redux/winners/types';
import { JSX } from 'react';

export function checkType(type: WINNERS_TOGGLE, topWinners:IWinner[], lastWinners:IWinner[], recentGames: IWinner[]): IWinner[] {
	switch (type) {
		case WINNERS_TOGGLE.RECENTLY_PLAYED_GAMES:
			return recentGames;
		case WINNERS_TOGGLE.TOP_WINS:
			return topWinners;
		case WINNERS_TOGGLE.LAST_WINS:
			return lastWinners;
		default:
			return [];
	}
}

export function checkTypeTitle(type: WINNERS_TOGGLE, lang: { [key: string]: JSX.Element } ) {
	switch (type) {
		case WINNERS_TOGGLE.RECENTLY_PLAYED_GAMES:
			return lang.last_games;
		case WINNERS_TOGGLE.TOP_WINS:
			return lang.top_winners;
		case WINNERS_TOGGLE.LAST_WINS:
			return lang.last_winners;
		default:
			return [];
	}
}

export const lengthRecentGameForShow = 30;