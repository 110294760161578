import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GameItemDistributor from '../../components/games/game-item/game-item-distributor';
import { lengthRecentGameForShow } from '../../components/layout/winners/utils';
import SpinLoader from '../../components/uiElements/SpinLoader/SpinLoader';
import { winnersSelectors } from '../../redux/winners/selectors';
import { useAppDispatch } from '../../hooks/storeHooks';
import winnersActions from '../../redux/winners/actions';
import { IGame } from '../../redux/games/games_list/types';
import { translate } from '../../translations/utils';
import Preloader from '../../components/preloader';
import NoData from '../../components/no-data';
import './recentlyPlayedGames.scss';

const RecentlyPlayedGames = () => {
	const recentGames: IGame[] = useSelector(winnersSelectors.selectRecentlyPlayedGames);
	const { hasMore, loading } = useSelector(winnersSelectors.selectWinnersUI);
	const [startPage, setStartPage] = useState(1);
	const dispatch = useAppDispatch();

	const onLoadMore = () => {
		setStartPage(startPage + 1);
		dispatch(winnersActions.getRecentlyPlayedAction({ page: startPage + 1, limit: lengthRecentGameForShow }));
	};

	useEffect(() => {
		if (recentGames && !recentGames.length) {
			dispatch(winnersActions.getRecentlyPlayedAction({ page: startPage, limit: lengthRecentGameForShow }));
		}
		// eslint-disable-next-line
    }, []);

	if (loading) {
		return <div className="custom_container">
			<Preloader section="gamesList"/>
		</div>;
	}

	if (!loading && !recentGames.length && !hasMore) {
		return (
			<div className="custom_container no_data">
				<NoData noDataTitle='haventAnyPlayedGames' noInfo/>
			</div>
		);
	}

	return (
		<div className='custom_container'>
			<span className='last_games_title'><FormattedMessage id="last_games"/></span>
			<div className='recent_played_games'>
				{recentGames.map((item: IGame) => (
					<div className='recent_played_item' key={item.id}>
						<GameItemDistributor record={item as IGame}/>
					</div>
				))}
			</div>
			{hasMore && (
				<div>
					{!loading ? (
						<button
							aria-label={translate('see_all')}
							className="see_all_btn"
							onClick={onLoadMore}
						>
							<FormattedMessage id="see_all"/>
						</button>
					) : (
						<SpinLoader/>
					)}
				</div>

			)}
		</div>
	);
};

export default RecentlyPlayedGames;