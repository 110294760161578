import LazyImage from '../../LazyImage/LazyImage';
import './spinLoader.scss';

const SpinLoader = () => {
	return (
		<div className="spin_loader">
			<LazyImage  width="200" height="200" src="/resources/images/svg/loader.svg" alt="" title=""   /> 
		</div>
	);
};

export default SpinLoader;