import { ILanguage, IUi } from '../types';
import { ICategory, IGame, IProvider, ICustomProvider } from '../games/games_list/types';
import { AxiosHeaders } from 'axios';
import { NumberOrString, StringOrNull } from '../../types/types';
import { IBanner } from '../banners/types';
import { CHANNELS } from './utils';
import { CurrencyList, PickOne } from '../../components/deposit/types';

type TStringOrNull = StringOrNull;

export interface IInitSettings {
  countries           : ICountry[];
  currencies          : ICurrency[];
  current_language    : Partial<ILanguage> | undefined | null;
  layout              : TLayout;
  providersAllEntities: IProvidersAllEntities;
  regFields           : IUsersRegFieldEntities;
  loader              : { section: string; data: TParamsType };
  scripts             : ScriptItem[];
  state               : { section: string; data: TParamsType };
  initialSettings     : Partial<ISettings> ;
  languages           : ILanguage[];
  SEO                 : ISEO;
  appMediaSize        : string;
  UI                  : Partial<ISettingsUI>;
  cookieScripts       : ScriptItem[];
  slider              : IBanner[];
  promotions?         : TParamsType;
  games?              : TParamsType;
  selectedCategory    : number;
  selectedSubIDs      : Array<number>;
  channelID           : CHANNELS;

}

export interface ISEO {
  pageName      : TStringOrNull;
  seoTitle      : TStringOrNull;
  seoDescription: TStringOrNull;
  seoKeywords   : TStringOrNull;
  ogTitle       : TStringOrNull;
  ogDescription : TStringOrNull;
  ogImageURL    : TStringOrNull;
}

export interface ISEOResponse {
  alias          : string;
  seo_title      : TStringOrNull;
  seo_description: TStringOrNull;
  seo_keywords   : TStringOrNull;
  og_title       : TStringOrNull;
  og_description : TStringOrNull;
  og_image       : TStringOrNull;
}


export type TAdaptList = IGame[] | ICategory[] | ICustomProvider[] | IProvider[];
export interface IAdapt extends ISEOResponse{
  name: string;
}

export type TLayout = 'error' | 'game' | 'promotion' | '';

export interface ISettingsUI extends IUi {
  data?        : boolean;
  loading      : boolean;
  appStarted   : boolean;
  appReady     : boolean;
  hideAppHeader: boolean;
}
export interface ICookieScript {
  [key: string]: string;
}

export interface ICountry {
  label: string;
  value: string;
  code : string;
}


export interface DateTimeResult {
  daysFormat : string;
  timeFormats: string;
}


export interface IGetValueCookieResult {
  type : string;
  value: number[];
}

export interface ScriptPosition {
  head: number;
  body: number;
}

export interface ScriptItem {
  id         : number;
  position_id: number;
  script     : string;
  type       : number;
  website_id : number;

}


export interface ICurrency {
  id         : number;
  website_id : number;
  currency_id: number;
  order_id   : number;
  description: string;
  code       : PickOne<CurrencyList>;
}

export interface ICurrencyPayload {
    data   : ICurrency[];
    section: string;
}

export interface ScriptSeparationResult {
  cookieScripts: ScriptItem[];
  scriptsNone  : ScriptItem[];
}


export interface ApiRequestConfig<T,P> {
  method         : string;
  url            : string;
  lang_id?       : number;
  params       ? : P;
  headers        : Partial<AxiosHeaders>;
  hideError?     : boolean;
  data           : T;
  withoutLangID ?: boolean;
}

// ApiResponse type
export interface ApiResponse<T> {
  data  : T;
  status: number;
}


export interface ISettingsAction {
  type: string;
  data: IGetSettingsPayload;
}

type TParamsType = {
  group_name        : string;
  request_from_front: boolean;
  website_id        : number;
  channel_id        : number;
};


export interface IGetSettingsPayload {
      locale       : string;
      langID       : number;
      withNavigate?: boolean;
}


export type TParams = Record<string, TParamsValue>;
export interface IParams extends Record<NumberOrString, TParamsValue>{

}

export  type TParamsValue = NumberOrString | boolean | null  | number[] | string[];


type TLanguage = {
  lang_id: number;
  code   : string;
};


export interface ISettings {

  date_format         : string;
  customer_age        : number;
  suspend_all         : boolean;
  suspend_prematch    : boolean;
  suspend_inplay      : boolean;
  inplay_delay        : number;
  prematch_delay      : number;
  lang_id             : number;
  game_load_type      : number;
  sub_category_type   : number;
  website_languages   : TLanguage[];
  require_cookie      : boolean;
  seo_title           : string;
  seo_description     : string;
  seo_keywords        : string;
  og_title            : string;
  og_description      : string;
  og_image            : string;
  national_id         : false;
  timeFormats?        : string;
  daysFormat?         : string;
  analytics_id        : string;
  enable_ocr          : boolean;
  resendEmailTime     : number;
  enable_game_grid    : boolean;
  enable_all_games    : boolean;
  user_show_fields    : WebsiteUserShowRequiredFields[];
  user_required_fields: WebsiteUserShowRequiredFields[];
}

export interface IStoreData {
  current_language: Partial<ILanguage> | null | undefined;
}

export interface IInitialSettingsPayload {
    lengId: number;
}

export enum GAME_LOAD_TYPE {
  INFINITE_SCROLL = 1,
  SHOW_MORE       = 2,
}

export interface IProvidersAllEntities {
  [key: string | number]: IProvider;
}

export enum WebsiteUserShowRequiredFields {
  USERNAME = 'username',
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  GENDER = 'gender',
  CURRENCY_ID = 'currency_id',
  BIRTH = 'birth',
  PHONE = 'phone',
  COUNTRY = 'country',
  CITY = 'city',
  ADDRESS_1 = 'address_1',
  ADDRESS_2 = 'address_2',
  NATIONAL_ID = 'national_id',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'password_confirmation',
}

export interface IUsersRegField {
    id      : number;
    key     : string;
    name    : string;
    show    : boolean;
    required: boolean;
    disabled: boolean;
}

export interface IUsersRegFieldEntities {
    [key: string]: IUsersRegField;
}
