import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Link, Navigate, Params, useLocation } from 'react-router-dom';
import { Statuses } from '../../constants/common';
import profileActions from '../../redux/profile/actions';
import { useAppDispatch } from '../../hooks/storeHooks';
import { profileSelectors } from '../../redux/profile/selectors';
import './verificationPage.scss';
import { useScrollTop } from '../../hooks/useScrollTop';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultLanguages } from '../../redux/settings/utils';
import { ROUTE_PATH } from '../../routes/utils';


const {
	selectProfileUI,
} = profileSelectors;

const VerificationPage = () => {
	const { pathname } = useLocation();
	const [isReq, setIsReq] = useState(false);
	const { hash, locale } = useParams<Params<string>>();
	const dispatch = useAppDispatch();
	const { statusEmailVerification } = useSelector(selectProfileUI);
	const isKnownLocale = defaultLanguages.some((lang) => lang.code === locale);
	useScrollTop();

	useEffect(() => {
		if (pathname.includes('user/verify') && statusEmailVerification !== Statuses.success) {
			setIsReq(true);
			isReq && dispatch(profileActions.confirmEmail(hash || ''));
		}
	}, [dispatch, hash, isReq, pathname, statusEmailVerification]);

	if (!isKnownLocale) {
		const url = `${ROUTE_PATH.verification.replace(':hash', hash || '')}`;
		return <Navigate to={`/${defaultLanguages[0].code}/${url}`} />;

	}
	return (
		<div className="container_verification_page">
			{statusEmailVerification !== Statuses.none && (
				<Link className="home_btn" to="/"><FormattedMessage id="go_to_home"/></Link>
			)}
		</div>
	);
};

export default VerificationPage;