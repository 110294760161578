import { FC, useEffect } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRootAppNavigate } from '../../hooks/useAppNavigate';
import { selectSettings } from '../../redux/settings/selectors';
import Sidebar from '../layout/sidebar';
import MainLayout from '../layout/page-layout/main-layout';
import { GA4 } from '../Analytics/GA4';
import { routerReducers } from '../../redux/router/slice';
import { logger } from '../../helpers/debugLogger';
import { settingsReducers } from '../../redux/settings/slice';
import { getAbsolutePaths } from '../../routes/utils';
import { SEO_RESET_EXCLUDES } from '../../config';
import { defaultLanguages } from '../../redux/settings/utils';

interface RootProps {
}

const {
	setNavigateEnd,
} = routerReducers;
const Root: FC<RootProps> = () => {
	const { navigateEnd, history } = useSelector(({ CustomHistory }) => CustomHistory);
	const { to, options } = history;
	const navigate = useRootAppNavigate();
	const params = useParams<{ locale: string }>();
	const { locale } = params;
	const { pathname, search } = useLocation();
	const { languages, current_language } = useSelector(selectSettings);
	const dispatch = useDispatch();

	useEffect(() => {
		try {

			if ((locale && current_language) && (locale !== current_language.code)) {
				const isKnownLocale = defaultLanguages.some((lang) => lang.code === locale);
				if (!isKnownLocale){
					const replacedUrl = pathname.replace(`/${locale}`, '');
					navigate(replacedUrl);
				}
			}
		} catch (e) {
			logger.log(e);
		}
	}, [current_language, languages, locale, navigate, pathname, search]);

	useEffect(() => {
		if (to && navigateEnd) {
			dispatch(setNavigateEnd(false));
			navigate(to, options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigateEnd, to]);

	const paths = getAbsolutePaths();

	const lengthParams = Object.keys(params).length;
	useEffect(() => {
		paths.map(path => {
			if (pathname.includes(path) && lengthParams === 1) {
				dispatch(settingsReducers.resetSEOData());
			} else if (!SEO_RESET_EXCLUDES.find(item => Object.keys(params).includes(item))) {
				dispatch(settingsReducers.resetSEOData());
			}
		});
		// eslint-disable-next-line
    }, [pathname]);


	return (
		<>
			<GA4/>
			<Sidebar/>
			<div className="root_inner">
				<MainLayout>
					<Outlet/>
				</MainLayout>
			</div>
		</>
	);
};

export default Root;
