export const getClientInfo = () => {
	const unknown = 'unknown';
	const _window = window;
	let width;
	let height;

	// screen
	let screenSize = '';
	if (_window.screen.width) {
		width = (_window.screen.width) ? _window.screen.width : '';
		height = (_window.screen.height) ? _window.screen.height : '';
		screenSize += '' + width + ' x ' + height;
	}

	// browser
	const nVer = navigator.appVersion;
	const nAgt = navigator.userAgent;
	let browser = navigator.appName;
	let version = '' + parseFloat(navigator.appVersion);
	let nameOffset, verOffset, ix;

	if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
		browser = 'Opera';
		version = nAgt.substring(verOffset + 6);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) {
			version = nAgt.substring(verOffset + 8);
		}
	}
	if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
		browser = 'Opera';
		version = nAgt.substring(verOffset + 4);
	}
	else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
		browser = 'Microsoft Edge';
		version = nAgt.substring(verOffset + 5);
	}
	else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
		browser = 'Microsoft Internet Explorer';
		version = nAgt.substring(verOffset + 5);
	}
	else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
		browser = 'Chrome';
		version = nAgt.substring(verOffset + 7);
	}
	else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
		browser = 'Safari';
		version = nAgt.substring(verOffset + 7);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) {
			version = nAgt.substring(verOffset + 8);
		}
	}
	else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
		browser = 'Firefox';
		version = nAgt.substring(verOffset + 8);
	}
	else if (nAgt.indexOf('Trident/') !== -1) {
		browser = 'Microsoft Internet Explorer';
		version = nAgt.substring(nAgt.indexOf('rv:') + 3);
	}
	else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
		browser = nAgt.substring(nameOffset, verOffset);
		version = nAgt.substring(verOffset + 1);
		if (browser.toLowerCase() === browser.toUpperCase()) {
			browser = navigator.appName;
		}
	}
	// trim the version string
	if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
	if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
	if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

	const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

	let cookieEnabled = !!(navigator.cookieEnabled);

	if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
		document.cookie = 'testcookie';
		cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
	}

	let os = unknown;
	const clientStrings = [
		{ s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
		{ s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
		{ s: 'Windows Vista', r: /Windows NT 6.0/ },
		{ s: 'Windows Server 2003', r: /Windows NT 5.2/ },
		{ s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
		{ s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
		{ s: 'Windows 98', r: /(Windows 98|Win98)/ },
		{ s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
		{ s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: 'Windows CE', r: /Windows CE/ },
		{ s: 'Windows 3.11', r: /Win16/ },
		{ s: 'Android', r: /Android/ },
		{ s: 'Open BSD', r: /OpenBSD/ },
		{ s: 'Sun OS', r: /SunOS/ },
		{ s: 'Linux', r: /(Linux|X11)/ },
		{ s: 'iOS', r: /(iPhone|iPad|iPod)/ },
		{ s: 'Mac OS X', r: /Mac OS X/ },
		{ s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: 'QNX', r: /QNX/ },
		{ s: 'UNIX', r: /UNIX/ },
		{ s: 'BeOS', r: /BeOS/ },
		{ s: 'OS/2', r: /OS\/2/ },
		{ s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
	];
	for (const id in clientStrings) {
		const cs = clientStrings[id];
		if (cs.r.test(nAgt)) {
			os = cs.s;
			break;
		}
	}

	let osVersion = unknown;

	if (/Windows/.test(os)) {
		// @ts-expect-error Object is possibly 'null'.
		osVersion = /Windows (.*)/.exec(os)[1];
		os = 'Windows';
	}

	switch (os) {
		case 'Mac OS X':
			const result = /Mac OS X (10[._\d]+)/.exec(nAgt);
			osVersion = (result !== null ? result[1] : '');
			break;

		case 'Android':
			// @ts-expect-error Object is possibly 'null'.
			osVersion = /Android ([._\d]+)/.exec(nAgt)[1];
			break;

		case 'iOS':
			// @ts-expect-error Object is possibly 'null'.
			osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
			// @ts-expect-error left
			osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
			break;

		default:
	}

	let flashVersion = 'no check';
	// @ts-expect-error add swfobject to window
	const swfobject = _window.swfobject;
	if (typeof swfobject !== 'undefined') {
		const fv = swfobject.getFlashPlayerVersion();
		if (fv.major > 0) {
			flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
		}
		else  {
			flashVersion = unknown;
		}
	}

	return {
		screen         : screenSize,
		screen_width   : width?.toString(),
		screen_height  : height?.toString(),
		browser        : browser,
		browser_version: version,
		os             : os,
		os_version     : osVersion.toString(),
		mobile         : mobile.toString(),
		cookies        : cookieEnabled.toString(),
		flash_version  : flashVersion,
	};
};


export const getDomain = () => {
	const domain = window.location.hostname;
	const protocol = window.location.protocol;

	return domain ? `${protocol}//${domain}` : '';
};