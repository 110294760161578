import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { translate } from '../../../translations/utils';
import LazyImage from '../../LazyImage/LazyImage';
import { IGameProps } from './types';
import './game-item.scss';


const Game: FC<IGameProps> = ({
	name,
	imgSrc,
	providerName,
	handleClick,
}) => {


	return (
		<div className="game_item">
			<div className="item_inner">
				<div className="game_name">{name}</div>
				<div className="game_image">
					<LazyImage width="270" height="164" src={imgSrc || ''} alt={name} title={name} />
				</div>
				<div className="game_info">
					<div className="game_description">{providerName}</div>
					<button aria-label={translate('play')} className="play_btn icon_play" onClick={handleClick}><span><FormattedMessage 
						id="play"/></span></button>
				</div>
			</div>
		</div>
	);
};

export default Game;
