import React from 'react';
import { createArray } from '../../../helpers/utils';

const GamesListPreloader: React.FC = () => {
	return (
		<>
			<div className="list_head">
				<span className="animate_block game_title_preloader"></span>
			</div>
			<ul className="games_preloader">
				{createArray(18).map((_, index) => { 
					return <li key={index}><span></span></li>;
				}) }
			</ul>
		</>
	);
};

export default GamesListPreloader;