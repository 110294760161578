import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { objectCloneDeep } from '../../helpers/utils';
import actions from './actions';
import Api from './api';
import {
	ICountry,
	ICurrency,
	IGetSettingsPayload,
	ISettings,
	IStoreData,
	ScriptItem,
} from './types';
import {
	adaptLanguage,
	detectLanguage,
	getDateAndTime,
	getValueCookie,
	injectToDocument, makeRegFields,
	replaceLang,
	separateScript,
} from './utils';
import { getCookie } from '../../helpers/cookies';
import CountrySelect from '../../helpers/countries';
import { AxiosApiResponse } from '../../service/types';
import { cookieActions } from '../cookie/actions';
import { settingsReducers as settingsActions } from './slice';
import { logger } from '../../helpers/debugLogger';
import { RootState } from '../store';
import { restoreLocale } from '../../helpers/localStorageUtils';
import { ISagaActionType } from '../types';
import { router } from '../../routes/browserRoutes';
import { cookieReducers } from '../cookie/slice';
import { gridGamesActions } from '../games/games_grid/actions';
import { gameGridReducers } from '../games/games_grid/slice';
import { setDocumentDirection } from '../../translations/utils';
import { IProvider } from '../games/games_list/types';
import { createEntities } from '../utils';

const API = new Api();


const getStoreData = (store: RootState): IStoreData => {
	return {
		current_language: store.Settings.current_language,
	};
};
function* getScripts() {

	yield takeLatest(actions.GET_SCRIPTS, function* () {
		try {
			const result: AxiosApiResponse<ScriptItem[]> = yield call(API.getScripts);

			if (result && result.status === 200) {
				const scripts = result.data.data;
				const { scriptsNone, cookieScripts } = separateScript(scripts);
				if (scriptsNone) {
					const scriptText = '';
					const liveChatMarker = 'window.__lc';
					if (scriptText.includes(liveChatMarker)) {
						return;
					}
					injectToDocument(scriptsNone);
				}
				const storageCookieType = getCookie('cookie_type');
				const { type: cookieType, value } = getValueCookie(storageCookieType);
				yield put(settingsActions.setScripts(scriptsNone));
				yield put(settingsActions.setCookieScripts(cookieScripts));
				if (!cookieType) {
					yield put(cookieReducers.setUI({ cookieAccept: false }));
				} else {
					yield put(cookieActions.attachCookies({ typeCookie: Number(cookieType), IDs: value }));
				}
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* getCurrencies() {
	yield takeLatest(actions.GET_CURRENCIES, function* () {
		try {
			const result: AxiosApiResponse<ICurrency[]> = yield call(API.getCurrencies);
			if (result && result.status === 200) {
				yield put(settingsActions.setCurrencies(result.data.data));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}


function* getInitSettings() {

	yield takeLatest(actions.GET_INITIAL_SETTINGS, function* (action: ISagaActionType<IGetSettingsPayload>) {
		const { current_language }: IStoreData = yield select(getStoreData);
		const { locale, withNavigate } = action.data;

		const params = {
			lang_id     : action.data.langID,
			fdgdsdffsdff: 1,
		};
		yield put(settingsActions.setSettingsUI({ loading: true, appReady: !!current_language?.id || !!params.lang_id }));
		try {
			const result: AxiosApiResponse<ISettings> = yield call(API.getInitialSettings, params);
			if (result && result.status === 200) {
				const res = result.data.data;
				res.date_format = res.date_format ? res.date_format : 'DD.MM.yyyy HH:mm';
				const { daysFormat, timeFormats } = getDateAndTime(res.date_format);
				res.daysFormat = daysFormat;
				res.timeFormats = timeFormats;
				const clonedData = objectCloneDeep(res);
				yield put(settingsActions.setInitialSettings(clonedData));
				if (res.enable_game_grid) {
					yield put(gridGamesActions.defaultGridReload());
					yield put(gameGridReducers.resetGridPages());
					
					yield put(gridGamesActions.defaultGridGamesReload([]));
				}
				const localeMissing = !restoreLocale();
				if (localeMissing) {
					const adaptedLanguage = adaptLanguage(res.lang_id, locale);
					setDocumentDirection(adaptedLanguage);
					yield put(settingsActions.setLanguage(adaptedLanguage));
				} else {
					const adaptedLanguage = detectLanguage(locale);
					yield put(settingsActions.setLanguage(adaptedLanguage));
					setDocumentDirection(adaptedLanguage);
					if (withNavigate) {
						const replacedPath: string = replaceLang(adaptedLanguage.code);
						yield call(router.navigate, replacedPath);
					}
				}
				const regFields = makeRegFields(res.user_show_fields, res.user_required_fields);
				yield put(settingsActions.setRegFields(regFields));

			}

		} catch (e) {
			logger.log('e', e);
		}
		yield put(settingsActions.setSettingsUI({ loading: false, appStarted: true }));

	});

}

function* getCountries() {

	yield takeLatest(actions.GET_COUNTRIES, function* () {
		try {

			const result: ICountry[] = yield call(CountrySelect);
			if (result) {
				yield put(settingsActions.setCountries(result));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* getAllProviders() {

	yield takeLatest(actions.GET_ALL_PROVIDERS, function* () {
		try {
			const result: AxiosApiResponse<IProvider[]> = yield call(API.getAllProvidersList);
			if (result.status === 200) {
				const { data } = result.data;

				const providersEntities = createEntities(data, 'provider_id');
				yield put(settingsActions.setProvidersEntities(providersEntities));
			}

		} catch (e) {
			logger.log('e', e);
		}
	});

}


function* settingsSaga() {
	yield all([
		fork(getCurrencies),
		fork(getInitSettings),
		fork(getCountries),
		fork(getScripts),
		fork(getAllProviders),
	]);
}

export default settingsSaga;
