export interface IMenuInitState {
	bottomMenu       : IBottomMenu;
	leftMenu         : ILeftMenu;
	bottomMenuContent: Partial<IBottomMenuContent>;
	mainMenu         : IMainMenuElement[];
	sidebar          : boolean;
	sitemap          : IWebsiteSiteMap;
}

export interface IMenuBase {
	items  : IMenuItem[];
	columns: IMenuColumns[];
}

export interface IBottomMenuUI {
	notificationVisible?: boolean;
	activeTabBottomMenu?: TABS;
	loading?            : boolean;
}
export interface IBottomMenu extends IMenuBase {
	UI: IBottomMenuUI;
}

export interface ILeftMenu extends IMenuBase {

}
export interface IMainMenuElement {
	id  : number;
	name: string;
	icon: string;
	url : string;
}
export interface IBottomMenuContent {
	id              : string;
	url             : string;
	name            : string;
	lang_id         : number;
	type_id         : number;
	image_url       : string;
	seo_title       : string;
	video_url       : string;
	link_title      : string;
	content_main    : string;
	seo_keywords    : string;
	content_short   : string;
	seo_description : string;
	content_title   : string;
	content_subtitle: string;
}
export interface ISitemap {
	url    : string;
	label  : string;
	locale?: boolean;
}

export interface IWebsiteSiteMap {
	[key: string]: ISitemap;
}

export interface IMenuResponse {
	items  : IMenuItem[];
	columns: IMenuColumnResponse[];
}
export interface IMenuColumnResponse {
	id               : number;
	title            : string;
	lang_id          : number;
	order_id         : number;
	display_status_id: MENU_DISPLAY_STATUS;
}
export interface IMenuItem {
	id               : number;
	url              : string;
	lang_id          : number;
	page_id          : number;
	order_id         : number;
	column_id        : number;
	image_url        : string;
	use_image        : boolean;
	use_video        : boolean;
	video_url        : string;
	link_title       : string;
	product_id       : number;
	content_title    : string;
	use_link_title   : boolean;
	content_subtitle : string;
	link_action_type : LINK_ACTION_TYPE;
	display_status_id: MENU_DISPLAY_STATUS;
	page_type_id     : number;
}


export interface IMenuColumns extends IMenuColumnResponse {
	menuItems: IMenuItem[];
}
export enum MENU_DISPLAY_STATUS {
	VISIBLE = 1,
	HIDDEN  = 2,
}

export enum LINK_ACTION_TYPE {
	SELF = 1,
	BLANK = 2
}
export enum MENU_IDS {
	bottomMenu = 1,
	bottomLeftMenu = 2,
}

export enum TABS {
	HOME = 'HOME',
	PROMOTION = 'PROMOTION',
	NOTIFICATION = 'NOTIFICATION',
	DEPOSIT = 'DEPOSIT',
	PROFILE = 'PROFILE',
	NONE = ''
}
