import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { storeLocale } from '../../helpers/localStorageUtils';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { settingsReducers } from '../../redux/settings/slice';
import settingsActions from '../../redux/settings/actions';
import { ILanguage } from '../../redux/types';
import LazyImage from '../LazyImage/LazyImage';
import { setDocumentDirection } from '../../translations/utils';

import './lang-switcher.scss';

const {
	getInitialSettingsAction: getSettings,
} = settingsActions;

const {
	setLanguage,
} = settingsReducers;

const LangSwitcher: React.FC = () => {

	const [checked, setChecked] = useState<boolean>(false);
	const languages = useAppSelector(state => state.Settings.languages);
	const current_language = useAppSelector(state => state.Settings.current_language);
	const dispatch = useAppDispatch();
	const handleClick = () => {
		setChecked(prev => !prev);
	};

	const handleClickAway = () => {
		setChecked(false);
	};


	const handleLangSelect = (langID: number) => {
		const language = languages.find((elem: ILanguage) => {
			return elem.id === langID;
		});
		if (language) {
			dispatch(setLanguage(language));
			dispatch(getSettings({ langID: language.id, locale: language.code, withNavigate: true }));
			storeLocale(language);
			setDocumentDirection(language);
		}
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway} >
			<div className="lg_block" onClick={handleClick}>
				<div className={`lg_list ${checked ? 'opened' : ''}`}>
					<div className="lg_btn icon_down">
						<LazyImage width="24" height="24" src={`/resources/images/flags/${current_language?.code}.svg`} alt="" title="" /> 
						{current_language?.name}
					</div>
					<Collapse in={checked}>
						<Paper elevation={4}>
							{
								languages.map((item, idx) => {
									if (current_language?.id === item.id) {
										return false;
									}
									return (
										<button aria-label={item.name} className={`language_${item.code}`} key={`language-${idx}`} onClick={() => handleLangSelect(item.id)} value={item.id}>
											{item.name}
										</button>
									);
								})
							}
						</Paper>
					</Collapse>
				</div>
			</div>
		</ClickAwayListener>
	);
};

export default LangSwitcher;
