import React, { Suspense, lazy, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobileDevice } from '../../../helpers/utils';
import actions from '../../../redux/socket/actions';
import appSocket from '../../../sockets/socket';
import { checkPage, isExitEvent } from './utils';
import { reStoreAuthToken } from '../../../helpers/localStorageUtils';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { gameReducers } from '../../../redux/games/games_list/slice';
import { notificationReducers } from '../../../redux/notifications/slice';
import { IMainLayoutProps } from './types';
import { live_chat_license } from '../../../config';
import { GAME_LOAD_TYPE } from '../../../redux/settings/types';
import { settingsUI } from '../../../redux/settings/selectors';
import Preloader from '../../preloader';
import { gamesSelectors } from '../../../redux/games/games_list/selectors';
import SpinLoader from '../../uiElements/SpinLoader/SpinLoader';

const LoginModal = lazy(() => {
	return import('../../modals/login');
});

const DepositeModal = lazy(() => {
	return import('../../modals/deposit');
});

const RegistrationModal = lazy(() => {
	return import('../../modals/registration');
});

const ForgetModal = lazy(() => {
	return import('../../modals/forget');
});

const ResetModal = lazy(() => {
	return import('../../modals/reset');
});

const InboxInnerModal = lazy(() => {
	return import('../../modals/inbox-inner');
});

const DeactivateModal = lazy(() => {
	return import('../../modals/deactivate');
});


const AvatarUploadModal = lazy(() => {
	return import('../../modals/avatar-upload');
});

const DeleteAvatarModal = lazy(() => {
	return import('../../modals/delete-avatar');
});

const TimeOutModal = lazy(() => {
	return import('../../modals/time-out');
});

const TournamentInnerGamesModal = lazy(() => {
	return import('../../modals/tournaments/tournament/tournamentGames');
});

const TournamentInnerTermsModal = lazy(() => {
	return import('../../modals/tournaments/tournament/tournamentTerms');
});

const InfoModal = lazy(() => {
	return import('../../modals/info');
});

const VerificationModal = lazy(() => {
	return import('../../modals/verification');
});

const TwoFAModal = lazy(() => {
	return import('../../modals/TwoFA/TwoFAModal');
});

const TwoFALoginModal = lazy(() => {
	return import('../../modals/TwoFaLogin/TwoFaLogin');
});

const ReactivateModal = lazy(() => {
	return import('../../modals/reactivate');
});

const Header = lazy(() => import('../header'));


const ConfirmModal = lazy(() => {
	return import('../../modals/confirm_modal/confirm_modal');
});

// const BonusConfirmModal = lazy(() => import('../../modals/bonus-confirm/bonus-confirm'));

const BonusDetailsModal = lazy(() => {
	return import('../../modals/bonus-details/bonus-details');
});

const BottomMenu = lazy(() => import('../bottom-menu'));

const Footer = lazy(() => import('../footer/footer'));

const CookieModal = lazy(() => {
	return import('../../modals/cookieModal');
});

const BalloonNotification = lazy(() => {
	return import('../../balloon-notification');
});

const LiveChat = lazy(() => import('../../controls/live-chat'));

const PhoneVerifyRequiredModal = lazy(() => import('../../modals/phoneVerifyRequired/PhoneVerifyRequiredModal'));


const {
	setUI: setNotificationUI,
} = notificationReducers;

const {
	setUI,
} = gameReducers;

const {
	selectCategories,
	selectSelectedCategory,
} = gamesSelectors;
const MainLayout: React.FC<IMainLayoutProps>  = (props) => {
	const { hideAppHeader  } = useAppSelector(settingsUI);
	const { initialSettings, current_language } = useAppSelector((store) => store.Settings);
	const { game_load_type } = initialSettings;
	const categories			= useAppSelector(selectCategories);
	const checkedCategory		= useAppSelector(selectSelectedCategory);
	const selectedCategory		= categories.find(item => item.id === checkedCategory);
	const selectedCategoryAlias	= selectedCategory?.alias;

	const onFocused = useRef(false);
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const showHeaderAndFooter	= !hideAppHeader;
	const loadTypeInfinite = GAME_LOAD_TYPE.INFINITE_SCROLL === game_load_type;
	
	const showFooter			= !(loadTypeInfinite && (
		pathname.includes('search-result')
			|| pathname === `/${current_language?.code}`
			|| hideAppHeader
			|| pathname.includes(`/${current_language?.code}/${selectedCategoryAlias}`)
	));


	const onFocus = useCallback((isDeviceMobile: boolean, init = false) => {
		const token = reStoreAuthToken();
		if (isDeviceMobile) {
			dispatch(actions.reconnect(token || ''));
			appSocket.socket.connect();

			if (!init && !onFocused.current) {
				onFocused.current = true;
				// dispatch(profileActions.profileDataReload());
				dispatch(setNotificationUI({ dropDownVisible: false }));
			}
		}
	}, [dispatch]);

	// User has switched away from the tab
	const onBlur = useCallback((isDeviceMobile: boolean) => {
		isDeviceMobile && appSocket.socket.disconnect();
		onFocused.current = false;
	}, []);


	// window message handler
	const onMessage = useCallback((e: MessageEvent) => {
		const { data } = e;
		const hasExitedGame = isExitEvent(data);
		if (hasExitedGame) {
			dispatch(setUI({ hasExitedGame }));
		}
	}, [dispatch]);


	useEffect(() => {
		const isDeviceMobile: boolean = isMobileDevice();
		window.addEventListener('focus', () => onFocus(isDeviceMobile));
		window.addEventListener('blur', () => onBlur(isDeviceMobile));
		window.addEventListener('message', onMessage);
		// Calls onFocus when the window first loads
		onFocus(isDeviceMobile, true);
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener('focus', () => onFocus(isDeviceMobile));
			window.removeEventListener('blur', () => onBlur(isDeviceMobile));
			window.removeEventListener('message', onMessage);
		};
	}, [onBlur, onFocus, onMessage]);


	return (
		<Suspense fallback={<Preloader section={checkPage(pathname)}/>} >
			<LoginModal/>
			<DepositeModal/>
			<RegistrationModal/>
			<ForgetModal />
			<ResetModal />
			<InboxInnerModal />
			<DeactivateModal />
			<AvatarUploadModal />
			<DeleteAvatarModal />
			<TimeOutModal />
			<TournamentInnerGamesModal />
			<TournamentInnerTermsModal />
			<InfoModal />
			<VerificationModal/>
			<TwoFAModal/>
			<TwoFALoginModal/>
			<ReactivateModal />
			{showHeaderAndFooter && <Header/>}
			<ConfirmModal />
			{/* <BonusConfirmModal /> */}
			<BonusDetailsModal />
			<PhoneVerifyRequiredModal />
			{props.children}
			<BottomMenu />
			<Suspense fallback={<SpinLoader/>}>
				{showFooter && <Footer/>}
			</Suspense>
			<CookieModal/>
			<BalloonNotification />
			<Suspense fallback={<SpinLoader/>}>
				{live_chat_license && <LiveChat />}
			</Suspense>
		</Suspense>
	);
};


export default MainLayout;
