import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Desktop } from '../../helpers/devices';
import SidebarUserActions from '../sidebar/sidebar-user-actions';
import { Converter } from '../../helpers/converter';
import { currencyList } from '../../config';
import { ROUTE_PATH } from '../../routes/utils';
import { AppNavLink } from '../uiElements/NavLink/AppNavLink/AppNavlink';
import { profileSelectors } from '../../redux/profile/selectors';
import { useAppSelector } from '../../hooks/storeHooks';
import OutsideClickDetector from '../../helpers/OutsideClickDetector';
import TotalBalance from './balanceDropDown/totalBalance';
import BalanceDropDown from './balanceDropDown';
import LazyImage from '../LazyImage/LazyImage';

import './header-profile.scss';

const {
	selectProfileData,
	selectTempAvatar,
} = profileSelectors;
const HeaderProfile = ({ isHeader = false }: {isHeader?: boolean}) => {
	const profileData = useAppSelector(selectProfileData);
	const tempAvatar = useAppSelector(selectTempAvatar);
	const { pathname } = useLocation();
	const [showBalanceDropDown, setShowBalanceDropDown] = useState(false);
	if (!profileData) {
		return false;
	}
	const converter = new Converter();

	const { first_name, last_name, username, email } = profileData;

	const name = (!first_name && !last_name && username) ? username :
		!first_name && !last_name && email ? email :
			`${first_name} ${last_name ? last_name : ''}`;

	const showDropdown = isHeader && profileData.bonus_balance;
	const currencyIcon = currencyList[profileData.currency_code as keyof typeof currencyList] || profileData.currency_code || '';
	const balance = converter.toCompactString(String(profileData.balance || ''), profileData.bonus_balance);
	const number = parseFloat(String(profileData.balance || '') || '0') + parseFloat(String(profileData.bonus_balance));
	const balanceWithoutK = (Math.sign(number) * Math.abs(number)).toFixed(2);

	const toggleDrowDown = (e: { stopPropagation: () => void }) => {
		if (!showDropdown) return;
		e.stopPropagation();
		setShowBalanceDropDown(!showBalanceDropDown);
	};
	const userImg = tempAvatar ? tempAvatar : profileData.avatar ? profileData.avatar : '/resources/images/avatar.webp';

	return (
		<div className="profile_block">
			<div className="user_block">
				<OutsideClickDetector onOutsideClick={() => setShowBalanceDropDown(false)}>
					<div className='user_info_wrapper'>
						<AppNavLink to={ROUTE_PATH.profile} className="user_info">
							<span className="user_image">
								<LazyImage
									width={profileData.avatar ? '32' : '720'}
									height={profileData.avatar ? '32' : '720'}
									src={userImg}
									alt='' title='' /> 
							</span>
							<span className="name_balance">
								<span className="user_name">{name}</span>
								<span className="user_balance" onClick={toggleDrowDown}>
									<TotalBalance
										currencyIcon={currencyIcon}
										balance={balance}
										className='totalBalanceWrapper'
									/>
									{showDropdown
										? (
											<span className={`icon_down user_balance_dropBtn ${showBalanceDropDown ? 'user_balance_dropBtn_rotate' : ''}`}/>
										)
										: null}
								</span>
							</span>
						</AppNavLink>
					</div>
				</OutsideClickDetector>
				<AppNavLink className="deposit_btn icon_plus" to={`${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}/`}/>
				{showBalanceDropDown && <BalanceDropDown
					balance={balanceWithoutK}
					currencyIcon={currencyIcon}
					realBalance={profileData.balance}
					bonusBalance={profileData.bonus_balance}
				/>}
			</div>
			<SidebarUserActions/>
			<Desktop>
				<AppNavLink
					className={
						`deposit_btn icon_deposit
					    ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}`) ? 'active_nav_link' : ''}`
					}
					to={`${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}/`}
				>
					<span className='btn_text'><FormattedMessage id="deposit"/></span>
				</AppNavLink>
			</Desktop>
			<Desktop>
				<AppNavLink
					className={`sports_book_btn icon_bet ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.sports}`) ? 'active_nav_link' : ''}`}
					to={ROUTE_PATH.sports}>
					<span className='btn_text'><FormattedMessage id="sports"/></span>
				</AppNavLink>
			</Desktop>
		</div>
	);
};

export default HeaderProfile;
