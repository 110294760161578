import { lazy } from 'react';


const Tournaments = lazy(() => {
	return import('../pages/tournament/tournament-list'); // commented in stage
});

const Home = lazy(() => {
	return import('../pages/home');
});

const Profile = lazy(() => {
	return import('../pages/profile');
});

const Tournament = lazy(() => {
	return import('../pages/tournament/tournament-inner'); // commented in stage
});

const SearchResult = lazy(() => {
	return import('../pages/search-result');
});

const Game = lazy(() => {
	return import('../pages/game-page');
});

const ErrorPage = lazy(() => {
	return import('../pages/error-page');
});

const Promotions = lazy(() => {
	return import('../pages/promotion');
});

const PromotionInner = lazy(() => {
	return import('../pages/promotion-inner');
});

const Help = lazy(() => {
	return import('../pages/help');
});

const SidebarInfo = lazy(() => {
	return import('../pages/sidebar-info');
});

const VerificationPage = lazy(() => {
	return import('../pages/verification');
});

const Sports = lazy(() => {
	return import('../pages/sports/sports');
});

import DepositInApp from '../pages/tronlinkDepositInApp/depositInApp';
import { ErrorElement } from '../components/errorBoundary/ErrorElement';
import { objectCloneDeep } from '../helpers/utils';
import RecentlyPlayedGames from '../pages/RecentlyPlayedGames';


const TronLink = lazy(() => {
	return import('../components/tronlink');
});

export const ROUTE_PATH = {
	root               : '/',
	noMatch            : '*',
	errorPage          : '404',
	home               : '/:locale',
	paymentSuccess     : 'payment-success',
	paymentFail        : 'payment-fail',
	help               : 'help',
	profile            : 'profile',
	verification       : 'user/verify/:hash',
	withdraw           : 'withdraw',
	withdrawMethod     : 'withdraw/:payment_method',
	profileUrl         : ':url',
	paymentMethod      : 'deposit/:payment_method',
	deposit            : 'deposit',
	tournament         : 'tournament',
	tournamentInner    : 'tournament/:id',
	category           : ':category',
	gameInner          : 'game/:gameAlias',
	game               : 'game',
	gamesCategory      : 'games/:category',
	promotions         : 'promotions',
	promotionsInner    : 'promotions/:alias',
	searchResult       : 'search-result',
	infoPage           : 'info/:url',
	resetPassword      : 'resetPassword/:resetHash',
	bonus              : 'bonus',
	profileDeposit     : '/profile/deposit/',
	sports             : 'sports',
	tronlink           : 'tronlink',
	tronlinkJWT        : 'tronlink/:jwt',
	recentlyPlayedGames: 'recentlyPlayed',

};

export const PublicRoutes = [
	{
		path        : ROUTE_PATH.tournament, // commented in stage
		element     : <Tournaments />,
		errorElement: <ErrorElement id={ROUTE_PATH.tournament} />,

	},
	{
		path        : ROUTE_PATH.errorPage,
		element     : <ErrorPage />,
		errorElement: <ErrorElement id={ROUTE_PATH.errorPage} />,

	},
	{ // commented in stage
		path        : ROUTE_PATH.tournamentInner,
		element     : <Tournament />,
		errorElement: <ErrorElement id={ROUTE_PATH.tournamentInner} />,

	},
	{
		path        : ROUTE_PATH.home,
		element     : <Home />,
		errorElement: <ErrorElement id={ROUTE_PATH.home} />,

	},
	{
		path        : ROUTE_PATH.help,
		element     : <Help />,
		errorElement: <ErrorElement id={ROUTE_PATH.help} />,

	},
	{
		path        : ROUTE_PATH.gameInner,
		element     : <Game />,
		errorElement: <ErrorElement id={ROUTE_PATH.gameInner} />,

	},
	{
		path        : ROUTE_PATH.category,
		element     : <Home />,
		errorElement: <ErrorElement id={ROUTE_PATH.category} />,

	} ,
	{
		path        : ROUTE_PATH.promotions,
		element     : <Promotions />,
		errorElement: <ErrorElement id={ROUTE_PATH.promotions} />,

	},
	{
		path        : ROUTE_PATH.promotionsInner,
		element     : <PromotionInner />,
		errorElement: <ErrorElement id={ROUTE_PATH.promotionsInner} />,

	},
	{
		path        : ROUTE_PATH.searchResult,
		element     : <SearchResult />,
		errorElement: <ErrorElement id={ROUTE_PATH.searchResult} />,

	},
	{
		path        : ROUTE_PATH.infoPage,
		element     : <SidebarInfo />,
		errorElement: <ErrorElement id={ROUTE_PATH.infoPage} />,

	},
	{
		path        : ROUTE_PATH.resetPassword,
		element     : <Home />,
		errorElement: <ErrorElement id={ROUTE_PATH.resetPassword} />,

	},
	{
		path        : ROUTE_PATH.paymentSuccess,
		element     : <Home page="payment"/>,
		errorElement: <ErrorElement id={ROUTE_PATH.paymentSuccess} />,

	},
	{
		path        : ROUTE_PATH.sports,
		element     : <Sports />,
		errorElement: <ErrorElement id={ROUTE_PATH.sports} />,

	},
	{
		path        : ROUTE_PATH.tronlink,
		element     : <TronLink />,
		errorElement: <ErrorElement id={ROUTE_PATH.tronlink} />,

	},
	{
		path        : ROUTE_PATH.tronlinkJWT,
		element     : <DepositInApp />,
		errorElement: <ErrorElement id={ROUTE_PATH.tronlinkJWT} />,

	},
	{
		path        : ROUTE_PATH.verification,
		element     : <VerificationPage />,
		errorElement: <ErrorElement id={ROUTE_PATH.verification} />,

	},
];

export const PrivateRoutes = [
	...PublicRoutes,
	{
		path        : ROUTE_PATH.profile,
		element     : <Profile/>,
		errorElement: <ErrorElement id={ROUTE_PATH.profile} />,

		children: [
			{
				path        : ROUTE_PATH.profileUrl,
				element     : <Profile/>,
				errorElement: <ErrorElement id={ROUTE_PATH.profileUrl} />,
			},
			{
				path        : ROUTE_PATH.paymentMethod,
				element     : <Profile/>,
				errorElement: <ErrorElement id={ROUTE_PATH.paymentMethod} />,

			},
			{
				path        : ROUTE_PATH.withdrawMethod,
				element     : <Profile/>,
				errorElement: <ErrorElement id={ROUTE_PATH.withdrawMethod} />,

			},
		],
	},
	{
		path        : ROUTE_PATH.recentlyPlayedGames,
		element     : <RecentlyPlayedGames />,
		errorElement: <ErrorElement id={ROUTE_PATH.recentlyPlayedGames} />,
	},
];


export function getAbsolutePaths() {
	const clonePath = objectCloneDeep(ROUTE_PATH);
	const pathArray = Object.values(clonePath);
	return pathArray.map(item => {
		if (item.includes(':')) {
			const index = item.indexOf(':');
			return item.slice(0, index);
		} else {
			return item;
		}
	});
}
