import { FC, useEffect } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';
import { ILazyImage } from './types';

const LazyImage:FC<ILazyImage> = (props) => {
	const { width='80', height='80', alt, src, offsetLazy= 100 } = props;

	useEffect(() => {
		forceVisible();
	}, []);

	return (
		<LazyLoad style={{  height: '100%' }} offset={offsetLazy} >
			<img {...props} width={ width} height={height} src={src} alt={alt}  loading={'lazy'} />
		</LazyLoad>
	);
};

export default LazyImage;